import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    modules: {
        entry
    },

    state: {
        replay: 0
    },

    getters: {
        replay: state => state.replay
    },

    mutations: {
        setReplay: ( state, payload ) =>
        {
            state.replay = payload;
        }
    },

    actions: {
        setReplay: ( context, payload ) =>
        {
            context.commit( 'setReplay', payload );
        }
    }
});
