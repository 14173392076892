<template>
    <div class="landing-def">
        <transition name="slide-up" mode="out-in" @leave="onCoverLeave">

            <div class="cover" v-show="showCover" :key="'pre'">
                <div class="layer back">
                    <img class="background" src="/static/img/landing-background-small.jpg">
                    <img class="arm" src="/static/img/landing-arm-small.png" />
                </div>

                <div class="content-wrapper">
                    <div class="copy-wrapper">
                        <h2 class="copy-1">Droom jij er ook <br/>van om er even <br/>tussenuit te zijn?</h2>
                        <h2 class="copy-2">Pak je tas en <br/>maak kans op <br/>een vakantie!</h2>
                        <button class="stl" @click="liftCover">Ik wil meespelen</button>
                    </div>
                </div>
                <div class="layer front">
                    <img class="leaves leaf-1" src="/static/img/leaves/leaf-6.png"/>
                    <img class="leaves leaf-6" src="/static/img/leaves/leaf-5.png"/>

                    <img class="leaves leaf-2" src="/static/img/leaves/leaf-1.png"/>
                    <img class="leaves leaf-3" src="/static/img/leaves/leaf-3.png"/>
                    <img class="leaves leaf-4" src="/static/img/leaves/leaf-4.png"/>
                    <img class="leaves leaf-5" src="/static/img/leaves/leaf-5.png"/>
                </div>

                <footer class="cover-footer">
                    <div class="content-wrapper">
                        <!-- <img class="footer-img" src="/static/img/logos/footer.png" /> -->
                        <img class="footer-img" src="/static/img/logos/footer-big.png" />
                        <p class="disclaimer">Voor het organiseren van Staatsloterij is aan Staatsloterij bv vergunning verleend door de Kansspelautoriteit, trekking Laan van Hoornwijck 55 te Rijswijk. <br />Deelname vanaf 18 jaar. Als je aan deze actie meedoet, dan accepteer je onze <a :href="avLink" target="_blank">actievoorwaarden</a>. <br class="hide-for-medium-up" /><a href="https://www.nederlandseloterij.nl/privacy" target="_blank">Privacy Statement</a> Nederlandse&nbsp;Loterij.</p>
                    </div>
                </footer>
            </div>

        </transition>

        <div class="real-landing" :key="'main'">
            <v-panel>
                <div slot="ribbon" class="ribbon-wrapper">
                    <img class="wieken" src="/static/img/wieken.png" />
                    <img src="/static/img/ribbon-campaign.png" />
                </div>

                <h1>Maak kans op een Europarcs voucher t.w.v. &euro;{{ prizeAmount }},-</h1>
                <p>Droom je er de laatste tijd ook wel eens van? Lekker op vakantie er echt even helemaal tussenuit zijn... Dat begrijpen we bij Staatsloterij maar al te goed. En daarom is er nu Inpakken & Wegwezen. Speel het spel, vang zo veel mogelijk artikelen en maak kans op &eacute;&eacute;n van de {{ numVoucher }} vouchers voor een heerlijke vakantie! Is dat even een lekker vooruitzicht in deze tijd!</p>

                <button class="stl" slot="button" @click="continueRoute">Doe mee!</button>
            </v-panel>
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { gsap, TweenMax } from '@/vendor';

    import Panel from '@/app/components/Panel';

    export default {
        name: 'Landing',

        components: {
            'v-panel': Panel
        },

        data()
        {
            return {
                showCover: true
            };
        },

        computed: {
            prizeAmount()
            {
                return this.$store.getters['entry/all'].nyxid ? '500' : '250';
            },

            replay()
            {
                return this.$store.getters['replay'];
            },

            avLink()
            {
                if( this.$store.getters['entry/all'].nyxid )
                {
                    return '/static/pdf/actievoorwaarden-abo.pdf';
                }
                else
                {
                    return '/static/pdf/actievoorwaarden.pdf';
                }
            },

            numVoucher()
            {
                if( this.$store.getters['entry/all'].nyxid )
                {
                    return 250;
                }
                else
                {
                    return 40;
                }
            }
        },

        mounted()
        {
            gsap.to( '.wieken', { rotation: -360, repeat: -1, duration: 10, ease: 'none' });

            this.startAnim();

            switch( ( this.replay + 1 ) % 3 )
            {
                    case 0:
                        document.querySelector( '.bg-visual' ).style.backgroundImage = 'url( /static/img/huisje-bg.jpg )';
                        break;
                    case 1:
                        document.querySelector( '.bg-visual' ).style.backgroundImage = 'url( /static/img/huisje2-bg.jpg )';
                        break;
                    case 2:
                        document.querySelector( '.bg-visual' ).style.backgroundImage = 'url( /static/img/huisje3-bg.jpg )';
                        break;
            }
        },

        methods: {
            liftCover()
            {
                this.showCover = false;
                gsap.set( '.main-layout > .content', { zIndex: 3 });
                gsap.set( '.main-layout > .stl-label', { position: 'absolute' });
                // document.querySelector( '.main-layout > .content' ).style = 'z-index:3;';
            },

            onCoverLeave( el, done )
            {
                let tl = gsap.timeline({ onComplete: done });

                tl.to( el, { y: '-100%' });
            },

            continueRoute()
            {
                this.$router.push( '/game' );
            },

            startAnim()
            {
                TweenMax.set( '.leaves', { transformOrigin: '0% 100%' });
                let tl = gsap.timeline({ });
                tl.add( 'start', 0 );
                tl.add( 'frame1', 'start+=3' );

                tl.from( '.back', 2, { y: -10, ease: 'sine.out' }, 'start' );
                tl.from( '.front', 2, { y: -30, ease: 'sine.out' }, 'start' );
                tl.to( '.back .arm', 5, { x: '-5%', repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start' );
                tl.fromTo( '.leaf-2', 10, { rotation: -2 }, { rotation: 3, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start' );
                // tl.fromTo( '.leaf-1', 8, { rotation: -2 }, { rotation: 2, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.1' );
                tl.fromTo( '.leaf-6', 10, { rotation: 1 }, { rotation: -1, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.2' );
                tl.fromTo( '.leaf-5', 10, { rotation: -3 }, { rotation: 5, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.2' );
                tl.fromTo( '.leaf-3', 10, { rotation: -1 }, { rotation: 1, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.3' );
                tl.fromTo( '.leaf-4', 20, { rotation: -3 }, { rotation: 3, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.5' );
                // tl.fromTo( '.leaf-8', 20, { rotation: -35 }, { rotation: -28, repeat: -1, yoyo: true, ease: 'sine.inOut' }, 'start+=0.5' );

                tl.from( '.copy-wrapper .copy-1', 0.5, { x: -10, autoAlpha: 0, display: 'none', ease: 'sine.inOut' }, 'start' );
                tl.to( '.copy-wrapper .copy-1', 0.5, { x: 10, autoAlpha: 0, display: 'none', ease: 'sine.inOut' }, 'frame1-=0.5' );
                tl.from( '.copy-wrapper .copy-2', 0.5, { x: -10, autoAlpha: 0, display: 'none', ease: 'sine.inOut' }, 'frame1' );
                tl.from( '.copy-wrapper button', 0.5, { x: -10, autoAlpha: 0, ease: 'sine.inOut' }, 'frame1+=0.3' );
            },

            getAnimateIn()
            {
            }
        }
    };
</script>
