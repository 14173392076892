<template>
    <div class="panel-wrapper">

        <div class="ribbon-wrapper">
            <slot name="ribbon"></slot>
        </div>

        <div :class="{ 'panel': true, 'darken': darken }">
            <slot></slot>

            <div class="button-wrapper">
                <slot name="button"></slot>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        props: {
            'darken': {
                type: Boolean,
                default: false
            }
        },

        methods: {
        }
    };
</script>
